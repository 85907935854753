const API_URL = process.env.REACT_APP_API_URL;

export const Endpoint = {
    auth: {
        login: () => ({ url: `${API_URL}/login`, method: "POST" }),
    },
    list: {
        getList: (page: number, offset: number, from: string | null, to: string | null, country: string) => ({ url: `${API_URL}/api-get-list-of-visitors-with-pagination?limit=${page}&offset=${offset}${from !== null && to !== null ? `&from_unix=${from}&to_unix=${to}` : ""}${country !== "" ? `&country=${country}` : ""}`, method: "GET" }),
        getListTrackVisitors: (page: number, offset: number) => ({ url: `${API_URL}/api-get-list-of-track-visitors?limit=${page}&offset=${offset}`, method: "GET" }),
        getListOfCountries: () => ({ url: `${API_URL}/api-get-list-of-country`, method: "GET" }),
        deleteTrackVisitorTrackVisitor: (uuid: string) => ({ url: `${API_URL}/api-delete-track-visitor?track_visitor=${uuid}`, method: "DELETE" }),
        getPeople: () => ({ url: `${API_URL}/api-get-people`, method: "GET" }),
        getPeopleById: (peopleId:  number | undefined) => ({ url: `${API_URL}/api-get-people-by-id?people_id=${peopleId}`, method: "GET" }),
        getVisitorById: (companyId:  string | undefined) => ({ url: `${API_URL}/api-get-visitor-by-id?visitor_id=${companyId}`, method: "GET" }),
        apiGetMatchedPeopleWithTrackVisitors: () => ({ url: `${API_URL}/api-get-matched-people-with-track-visitors`, method: "GET" }),
        apiGetMatchingCompaniesWithTrackingVisitors: () => ({ url: `${API_URL}/api-get-matching-companies-with-tracking-visitors`, method: "GET" }),
    },
    user: {
        getUserProfile: () => ({ url: `${API_URL}/api-get-user-profile`, method: "GET" }),
        editUserProfile: () => ({ url: `${API_URL}/api-get-user-profile`, method: "GET" }),
        addTrackUser: () => ({ url: `${API_URL}/api-create-track-visitor`, method: "POST" }),
        getMostFrequentedVisitors: () => ({ url: `${API_URL}/api-get-most-frequented-visitors`, method: "GET" }),
        getDashboardBoxes: () => ({ url: `${API_URL}/api-get-dashboard-boxes`, method: "GET" }),
        getvisitorsTrackingLast30Days: () => ({ url: `${API_URL}/visitors-tracking-last-30-days`, method: "GET" }),
        getvisitorsTrackingLast30DaysByCountry: (country: string) => ({ url: `${API_URL}/visitors-tracking-last-30-days-by-country?country=${country}`, method: "GET" }),
        getMatchingVisitors: () => ({ url: `${API_URL}/api-get-matching-visitors`, method: "GET" })
    },
    notifications: {
        countNotifications: () => ({ url: `${API_URL}/api-count-notifications`, method: "GET" }),
        getAllNotifications: (page: number, offset: number) => ({ url: `${API_URL}/api-get-list-of-notifications?limit=${page}&offset=${offset}`, method: "GET" }),
        getNotificationDetails: (uuid: string) => ({ url: `${API_URL}/api-get-notification-details?notification_uuid=${uuid}`, method: "GET" }),
    },
};