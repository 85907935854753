// src/pages/Profile.tsx
import React, { useEffect, useState } from 'react';
import apiInstance from '../services/api';
import { Card, CardContent, CardHeader } from '../components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar';
import { Skeleton } from '../components/ui/skeleton';
import { User2 } from 'lucide-react';

const Profile: React.FC = () => {

    const [profileData, setProfileData] = useState<any>(null);

    const getUserProfile = async () => {
        try {
            const response = await apiInstance.apiGetUserProfile();
            setProfileData(response[0]);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUserProfile();
    }, [])

    return (
        <div>
            <h1 className='text-center text-[28px] mb-3 font-semibold flex items-center gap-2 justify-center'><User2 />Profile</h1>
            {
                profileData ?
                    <Card className='rounded-md p-4 relative'>
                        <CardContent>
                            <div className='bg-cover bg-[url(https://images.unsplash.com/photo-1533135091724-62cc5402aa20?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)] h-[100px] w-full absolute top-0 left-0 right-0 rounded-tr-md rounded-tl-md'>
                                <Avatar className=' min-w-[100px] w-[100px] h-[100px] absolute bottom-[-50%] left-[40px]'>
                                    <AvatarImage className='object-cover' src="https://captiontools.com/wp-content/uploads/2017/03/testy3-1.png" />
                                    <AvatarFallback>AA</AvatarFallback>
                                </Avatar>
                            </div>
                            <div className='flex w-full gap-3 mt-[150px]'>
                                <div className='flex-1'>
                                    <h3 className='mt-4'>Name</h3>
                                    <div className='border border-black dark:border-white border-opacity-25 dark:border-opacity-25 p-3 mt-2 rounded-md font-medium text-[18px]'>{profileData?.name}</div>
                                </div>
                                <div className='flex-1'>
                                    <h3 className='mt-4'>Last name</h3>
                                    <div className='border border-black dark:border-white border-opacity-25 dark:border-opacity-25 p-3 mt-2 rounded-md font-medium text-[18px]'>{profileData?.surname}</div>
                                </div>
                            </div>
                            <div className='flex w-full gap-3'>
                                <div className='flex-1'>
                                    <h3 className='mt-4'>Username</h3>
                                    <div className='border border-black dark:border-white border-opacity-25 dark:border-opacity-25 p-3 mt-2 rounded-md font-medium text-[18px]'>{profileData?.username}</div>
                                </div>
                                <div className='flex-1'>
                                    <h3 className='mt-4'>Created date</h3>
                                    <div className='border border-black dark:border-white border-opacity-25 dark:border-opacity-25 p-3 mt-2 rounded-md font-medium text-[18px]'>{profileData?.created_date}</div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    :
                    <Skeleton className="w-[100%] min-h-[410px] rounded-md" />
            }
        </div>
    );
};

export default Profile;
