import React, { useEffect, useState } from 'react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "../components/ui/dropdown-menu";
import { Button } from '../components/ui/button';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useToast } from "../components/ui/use-toast";
import { Avatar, AvatarImage, AvatarFallback } from '../components/ui/avatar';
import { Building2, List, Menu, Search, Users2 } from 'lucide-react';
import ThemeToggle from '../components/ThemeToggle';
import Notifications from '../components/Notifications/Notifications';
import apiInstance from '../services/api';
import { DashboardIcon } from '@radix-ui/react-icons';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../components/ui/collapsible';

const Layout: React.FC = () => {


    const navigate = useNavigate();
    const { toast } = useToast();

    const [isOpen, setIsOpen] = useState(false);

    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    const [notificationsCountNumber, setNotificationsCountNumber] = useState(null);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleCloseNotifications = () => {
        setIsNotificationsOpen(false);
    };

    const [isOpenSheet, setIsOpenSheet] = useState(false);

    const handleCloseSheet = () => {
        setIsOpenSheet(false);
    };

    const handleLogout = () => {
        toast({
            title: "You have been logged out.",
            duration: 1000,
        });
        setTimeout(() => {
            localStorage.removeItem('token');
            navigate('/');
        }, 1000);
    };

    const notificationsCount = async () => {
        try {
            const response = await apiInstance.apiCountNotifications();
            setNotificationsCountNumber(response.new_notifications);

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        notificationsCount();
    }, [isNotificationsOpen]);

    return (
        <>
            <div className="flex min-h-screen w-full flex-col md:flex-row">
                <header className="flex md:flex-col justify-between md:justify-start items-center gap-8 border-b p-4 shadow z-10 bg-background w-full md:w-[240px] md:fixed top-0 left-0 bottom-0">
                    <Link
                        to="/dashboard"
                        className="flex items-center gap-2 text-lg font-semibold md:text-base "
                    >
                        <svg className="h-full w-[140px]" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 142.75 31.36">
                            <g id="Group_8858" transform="translate(-927 -5411)">
                                <path id="Path_8429" className="st0 dark:fill-white" d="M963.5,5416.6h-8.32c-1.12,0-2.19,0.44-2.98,1.23l-1.74,1.75c-0.55,0.55-1.43,0.55-1.98,0.01
                                            c0,0,0,0-0.01-0.01l-1.74-1.75c-0.79-0.79-1.86-1.23-2.98-1.23h-8.32c-4.65,0-8.42,3.77-8.42,8.43c0,4.65,3.77,8.42,8.42,8.42h8.32
                                            c1.12,0,2.19-0.44,2.98-1.23l1.74-1.75c0.55-0.55,1.43-0.55,1.98-0.01c0,0,0,0,0.01,0.01l1.74,1.74c0.79,0.79,1.86,1.23,2.98,1.23
                                            h8.32c4.65,0,8.42-3.77,8.42-8.43C971.92,5420.37,968.15,5416.6,963.5,5416.6 M963.5,5427.83h-27.99c-1.41,0.02-2.63-1-2.87-2.39
                                            c-0.23-1.54,0.83-2.97,2.36-3.19c0.14-0.02,0.28-0.03,0.41-0.03h27.99c1.41-0.02,2.63,0.99,2.86,2.39c0.23,1.53-0.82,2.97-2.36,3.2
                                            C963.78,5427.82,963.64,5427.83,963.5,5427.83"></path>
                                <path id="Path_8430" className="st0 dark:fill-white" d="M980.53,5442.36v-18.2c-0.02-1.32,0.29-2.62,0.88-3.79c0.58-1.13,1.37-2.14,2.33-2.97
                                            c0.96-0.83,2.07-1.49,3.26-1.94c1.19-0.45,2.46-0.68,3.73-0.68c1.32,0,2.64,0.22,3.89,0.66c1.23,0.42,2.37,1.09,3.34,1.95
                                            c0.99,0.9,1.78,2,2.33,3.22c0.61,1.41,0.91,2.94,0.88,4.48c0.01,1.39-0.22,2.77-0.68,4.08c-0.43,1.24-1.09,2.38-1.95,3.36
                                            c-0.87,0.96-1.92,1.74-3.11,2.27c-1.3,0.58-2.72,0.86-4.14,0.84c-1.59,0.04-3.15-0.43-4.46-1.35c-1.17-0.81-2.14-1.89-2.81-3.15
                                            v11.2L980.53,5442.36z M990.97,5432.84c1.05,0.03,2.08-0.21,3.01-0.7c0.82-0.45,1.52-1.08,2.07-1.84c0.55-0.76,0.96-1.62,1.21-2.52
                                            c0.25-0.88,0.39-1.8,0.39-2.72c0.02-1.08-0.16-2.16-0.55-3.17c-0.33-0.86-0.84-1.64-1.48-2.29c-0.61-0.6-1.34-1.07-2.15-1.37
                                            c-0.8-0.3-1.65-0.45-2.5-0.45c-0.91-0.01-1.81,0.16-2.66,0.49c-0.83,0.32-1.58,0.81-2.21,1.43c-0.66,0.65-1.17,1.44-1.5,2.31
                                            c-0.71,1.93-0.73,4.05-0.06,6c0.31,0.9,0.79,1.74,1.41,2.46c0.61,0.71,1.35,1.3,2.19,1.72
                                            C989.02,5432.64,989.99,5432.86,990.97,5432.84"></path>
                                <path id="Path_8431" className="st0 dark:fill-white" d="M1014.46,5435.35c-1.05,0.23-2.12,0.34-3.2,0.35c-1.03,0.03-2.05-0.15-3.01-0.53
                                            c-0.78-0.33-1.45-0.87-1.93-1.56c-0.51-0.75-0.85-1.6-1.02-2.5c-0.21-1.1-0.3-2.22-0.29-3.34v-9.89h-2.7v-2.81h2.7V5411h3.48v4.06
                                            h5.43v2.81h-5.43v9.85c0,0.73,0.03,1.42,0.1,2.07c0.05,0.6,0.2,1.18,0.45,1.72c0.22,0.48,0.58,0.88,1.04,1.15
                                            c0.59,0.3,1.24,0.45,1.9,0.41c0.4,0,0.8-0.04,1.19-0.1c0.43-0.07,0.87-0.14,1.31-0.21L1014.46,5435.35z"></path>
                                <path id="Path_8432" className="st0 dark:fill-white" d="M1034.12,5430.23c-0.84,1.65-2.08,3.05-3.61,4.08c-1.51,0.88-3.24,1.32-4.98,1.27
                                            c-1.36,0.01-2.72-0.24-3.99-0.74c-1.2-0.48-2.28-1.19-3.18-2.11c-0.92-0.94-1.63-2.07-2.09-3.3c-0.51-1.38-0.76-2.85-0.74-4.32
                                            c-0.02-1.5,0.23-2.99,0.74-4.4c0.46-1.25,1.16-2.4,2.07-3.38c0.9-0.95,1.98-1.69,3.18-2.19c2.83-1.09,5.98-1.04,8.77,0.14
                                            c1.17,0.57,2.18,1.43,2.93,2.5c0.75,1.11,1.26,2.36,1.5,3.67c0.28,1.46,0.4,2.95,0.37,4.44H1019c0.03,1.04,0.25,2.07,0.66,3.03
                                            c0.35,0.82,0.86,1.55,1.5,2.17c0.6,0.57,1.31,1.01,2.09,1.29c0.75,0.28,1.54,0.43,2.34,0.43c0.99,0.02,1.96-0.22,2.83-0.68
                                            c0.83-0.47,1.55-1.12,2.09-1.9L1034.12,5430.23z M1031.62,5423.04c-0.02-0.76-0.17-1.52-0.45-2.23c-0.27-0.69-0.66-1.33-1.15-1.88
                                            c-0.51-0.56-1.14-1-1.84-1.29c-1.66-0.64-3.49-0.62-5.14,0.04c-0.74,0.32-1.41,0.77-1.99,1.33c-0.55,0.54-1.01,1.17-1.33,1.88
                                            c-0.32,0.68-0.52,1.4-0.61,2.15H1031.62z"></path>
                                <path id="Path_8433" className="st0 dark:fill-white" d="M1037.32,5424.43c-0.04-1.57,0.17-3.14,0.62-4.65c0.34-1.11,0.96-2.11,1.8-2.91
                                            c0.82-0.74,1.8-1.26,2.87-1.52c1.26-0.31,2.55-0.47,3.85-0.45c0.49,0,1.01,0.01,1.54,0.04s1.09,0.06,1.66,0.12v2.66
                                            c-1.25-0.08-2.42-0.12-3.5-0.12c-0.97-0.02-1.94,0.18-2.81,0.61c-0.84,0.45-1.51,1.18-1.9,2.05c-0.54,1.34-0.78,2.78-0.7,4.22
                                            v10.55h-3.44L1037.32,5424.43z"></path>
                                <path id="Path_8434" className="st0 dark:fill-white" d="M1066.27,5430.97c-0.67,1.3-1.64,2.42-2.83,3.26c-1.3,0.9-2.85,1.37-4.44,1.35
                                            c-1.42,0.05-2.84-0.22-4.14-0.78c-1.19-0.52-2.25-1.29-3.11-2.27c-0.87-1-1.53-2.15-1.95-3.4c-0.46-1.32-0.69-2.7-0.68-4.1
                                            c-0.03-1.53,0.27-3.05,0.88-4.46c0.55-1.22,1.34-2.32,2.33-3.22c0.97-0.88,2.11-1.55,3.34-1.97c1.25-0.44,2.56-0.66,3.89-0.66
                                            c1.28,0,2.55,0.23,3.75,0.68c1.2,0.44,2.3,1.1,3.26,1.94c0.95,0.83,1.73,1.84,2.31,2.97c0.59,1.17,0.89,2.48,0.88,3.79v10.94h-3.48
                                            L1066.27,5430.97z M1059.32,5432.77c1,0.02,1.98-0.2,2.87-0.65c0.83-0.42,1.57-1,2.17-1.72c0.61-0.73,1.08-1.56,1.39-2.46
                                            c0.67-1.95,0.65-4.07-0.06-6c-0.33-0.86-0.84-1.65-1.48-2.31c-0.62-0.62-1.37-1.11-2.19-1.43c-0.85-0.33-1.75-0.49-2.66-0.49
                                            c-0.85,0-1.7,0.15-2.5,0.45c-0.81,0.3-1.54,0.76-2.15,1.37c-0.65,0.65-1.16,1.43-1.5,2.29c-0.4,1.01-0.59,2.08-0.57,3.17
                                            c0,0.92,0.14,1.83,0.39,2.72c0.25,0.91,0.66,1.76,1.21,2.52c0.55,0.76,1.25,1.38,2.07,1.84
                                            C1057.23,5432.56,1058.27,5432.8,1059.32,5432.77"></path>
                            </g>
                        </svg>
                    </Link>
                    <div className="flex flex-row md:flex-col items-center md:w-full gap-4">
                        <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
                            <DropdownMenuTrigger asChild>
                                <Button variant="outline" size="icon" className="rounded-full">
                                    <Avatar>
                                        <AvatarImage className='object-cover' src="https://captiontools.com/wp-content/uploads/2017/03/testy3-1.png" />
                                        <AvatarFallback>AA</AvatarFallback>
                                    </Avatar>
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className='m-2' align="end">
                                <DropdownMenuItem className='p-0'>
                                    <Link className='w-full p-2' to="/profile" onClick={handleClose}>Profile</Link>
                                </DropdownMenuItem>
                                <DropdownMenuItem className='p-0'>
                                    <ThemeToggle />
                                </DropdownMenuItem>
                                <DropdownMenuSeparator />
                                <DropdownMenuItem className='p-0'>
                                    <Link className='w-full p-2' to="" onClick={() => { handleLogout(); handleClose(); }}>Logout</Link>
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                        <DropdownMenu open={isNotificationsOpen} onOpenChange={setIsNotificationsOpen}>
                            <DropdownMenuTrigger asChild>
                                <Button variant="outline" size="icon" className="rounded-md relative p-2 w-[80px] flex items-center gap-[6px]">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            className='dark:stroke-white stroke-black'
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M18 8A6 6 0 106 8c0 7-3 9-3 9h18s-3-2-3-9zM13.73 21a1.999 1.999 0 01-3.46 0"
                                        ></path>
                                    </svg>
                                    <h3 className='font-bold'>{notificationsCountNumber}</h3>
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className='max-w-[500px] w-[500px] m-2'>
                                {isNotificationsOpen ? <Notifications /> : null}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                    <div className='w-full hidden md:block'>
                        <NavLinks />
                    </div>
                </header>
                <Collapsible className='block md:hidden p-4 border'>
                    <CollapsibleTrigger className='flex items-center gap-2 bg-primary px-4 py-2 rounded-md text-white'>Menu <Menu /></CollapsibleTrigger>
                    <CollapsibleContent className='mt-3'>
                        <NavLinks />
                    </CollapsibleContent>
                </Collapsible>
                <main className="flex flex-1 flex-col gap-4 p-4 w-full max-w-[1500px] mx-auto">
                    <div className="block gap-4 md:ml-[246px]">
                        <Outlet />
                    </div>
                </main>
            </div>
        </>
    );
};

const NavLinks = () => {
    const { pathname } = useLocation();

    return (
        <nav className="flex flex-col gap-4 text-lg font-medium w-full">
            <Link
                className={`flex items-center gap-2 px-4 py-2 rounded-md text-foreground transition-all hover:opacity-50 ${/^\/dashboard(\/.*)?$/.test(pathname) ? 'bg-[hsl(var(--primary))] text-white' : ''}`}
                to="/dashboard"
            >
                <DashboardIcon width={20} height={20} />
                Dashboard
            </Link>
            <Link
                to="/people"
                className={`flex items-center gap-2 px-4 py-2 rounded-md text-foreground transition-all hover:opacity-50 ${/^\/people(\/.*)?$/.test(pathname) ? 'bg-[hsl(var(--primary))] text-white' : ''}`}
            >
                <Users2 width={20} height={20} />
                People
            </Link>
            <Link
                to="/companies"
                className={`flex items-center gap-2 px-4 py-2 rounded-md text-foreground transition-all hover:opacity-50 ${/^\/companies(\/.*)?$/.test(pathname) ? 'bg-[hsl(var(--primary))] text-white' : ''}`}
            >
                <Building2 width={20} height={20} />
                Companies
            </Link>
            <Link
                to="/tracking-list"
                className={`flex items-center gap-2 px-4 py-2 rounded-md text-foreground transition-all hover:opacity-50 whitespace-nowrap ${/^\/tracking-list(\/.*)?$/.test(pathname) ? 'bg-[hsl(var(--primary))] text-white' : ''}`}
            >
                <Search width={20} height={20} />
                Tracking list
            </Link>
            <Link
                to="/matching-lists"
                className={`flex items-center gap-2 px-4 py-2 rounded-md text-foreground transition-all hover:opacity-50 whitespace-nowrap ${/^\/matching-lists(\/.*)?$/.test(pathname) ? 'bg-[hsl(var(--primary))] text-white' : ''}`}
            >
                <List width={20} height={20} />
                Matching lists
            </Link>
        </nav>
    )
}

export default Layout;
